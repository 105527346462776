import logo from './logo.svg';
import './App.css';
import { useState } from 'react';
import captionate_logo from './logo.png'
import parse from 'html-react-parser';
import spin from './spin.gif'

function App() {
  const [image, setImage] = useState(null);
  const [captions, setCaptions] = useState("");
  const [imageFile,setImageFile] = useState("");
  const [captionType, setCaptionType] = useState("funny")
  const [error, setError] = useState(false);
  const [altText, setAltText] = useState(null)
  const [showAlt, setShowAlt] = useState(true)

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (e) => {
        setImage(e.target.result);
      };
      reader.readAsDataURL(e.target.files[0]);
      setImageFile(e.target.files[0]);
      setAltText(null)
    }
  };

  function convertToHtmlNumberedList(lines) {

    // Remove empty lines
    const nonEmptyLines = lines.filter(line => line.trim() !== '');
  
    // Add HTML tags to create a numbered list
    const htmlLines = nonEmptyLines.map((line, index) => `<li>${line}</li>`);
  
    // Join the lines together to create the final HTML string
    const html = `<ol>\n${htmlLines.join('\n')}\n</ol>`;
  
    return html;
  }
  
  function removeSpacesBetweenNumberAndPeriod(text) {
    // Use a regular expression to match any number followed by one or more spaces and a period
    const regex = /\d+\s+\./g;
  
    // Replace the matched text with the number and period with no spaces in between
    return text.replace(regex, match => match.replace(/\s+/, ''));
  }
  
  function convertTextToHTML(text) {
    const whitespace_cleaned_text = removeSpacesBetweenNumberAndPeriod(text);
    const numbered_array = whitespace_cleaned_text.split(/\d+\./);
    return parse(convertToHtmlNumberedList(numbered_array));
  }

  const [loading, setLoading] = useState(false);
  
  const sendRequest = async () => {
    setCaptions("")
    setLoading(true);
    const formData = new FormData()
    formData.append('imagefile', imageFile)
    formData.append('name', 'imagefile')
    formData.append("caption_type", captionType)
    if(altText){
      formData.append("alt_text",altText);
    }
    console.log(imageFile)
    console.log(formData)
    const fetchUrl = "https://us-central1-captionate-376204.cloudfunctions.net/uploadimagepremium";

    try{
      const response = await fetch(fetchUrl, {
        method: 'POST',
        headers: {
        },
        body: formData,
      });
      console.log("Received Response!")
      console.log(response)
      const responseData = await response.json();
      console.log(responseData);
      setLoading(false);
      setCaptions(responseData['captions']);
      setAltText(responseData['alt_text'].trim())
      setCaptionType("funny")
    }
    catch(err) {
      setError(true)
      console.log("Ran into an error")
    }
  };
  
  return (
    <div className="App">
      <div class="header">
      <img src={captionate_logo} />
      </div>
      <div class="file-upload">
        <div class="generate-container">
          <div class="image-upload-wrap">
          <input class="file-upload-input" type='file' onChange={handleImageChange} id="imagefile" name="imagefile" accept="image/*" />
          {
            image ? (
              <div class="file-upload-content">
                <img class="file-upload-image" src={image} alt="" />
              </div>
            ) : (
              <div class="drag-text">
                <p>Drag and drop a file</p>
                <p>or</p>
                <p> Click to Select Image</p>
              </div>
            )
          }
          </div>
          {!loading?
          <select id="caption_type"
          onChange={e => (setCaptionType(e.target.value))}
          >
            <option value="funny">I'm funny I promise</option>
            <option value="bb">Basic Girl</option>
            <option value="bd">Basic Guy</option>
            <option value="sasd">Self deprecating</option>
            <option value="qin">Emojis Only 😍</option>
          </select>
          :
            null
          }
          
          {altText && captions!==""?
            <div class="alt-text-container">
            <p>⬇️Hate the captions? Add details to the alt text below to make sure it's accurate⬇️</p>
            <textarea class="alt-text-textarea"type="text" value={altText} onChange={(e)=>setAltText(e.target.value)} name="alt_text" />
            <button class="file-upload-btn" type="submit" onClick={sendRequest}>Re-Captionate</button>
            </div>
            :
            null
          }
          
          {captions !== ""?
            <div class="caption-container">
            {convertTextToHTML(captions)}
            </div>
            :
            null
          }
          {
          image && !captions ? (
            loading || error ? (
                error?
                <h3>Sorry! We ran into an error...</h3>:
                <div>
                <h3>Loading...[can take >60 seconds]</h3>
                <p>{"[Please do not refresh/reload the page]"}</p>
                <img src={spin} />
                </div>
            )
            :
            (
              <div class="file-upload-content">
                <button class="file-upload-btn" type="submit" onClick={sendRequest}>Captionate</button>
              </div>
            )
          )
          : 
          null
        }
        </div>
        
      </div>
    </div>
    );
  }
  
  export default App;